import { CarProfile } from '@/models/carProfile'

import { formatKeysForTable } from '@/helpers/data-table'

export function formatVehicleForTheTable(
  car: CarProfile,
  picked: (keyof CarProfile)[]
) {
  const tableData = formatKeysForTable(car, picked)

  return tableData
}
