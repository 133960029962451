import {
  CommonModule,
  ModuleWithData,
  ModuleError,
  PaginationModule,
} from '@/models/store'

import {
  CarProfile,
  CarProfileFull,
  CarProfileStatus,
  CarProfileUpdateableKeys,
} from '@/models/carProfile'

import {
  getInitialCommonModuleState,
  getInitialDataModuleState,
  getPagintaionModuleState,
} from '@/helpers/store-state'

import { TripsByGroups } from '@/store/trips'

export type GetVehiclesParams = {
  status?: CarProfileStatus
  user_id?: string
}

// TODO: replace with common type
export interface VehicleUpdatingModule {
  loadingKeys: CarProfileUpdateableKeys[]
  successKeys: CarProfileUpdateableKeys[]
  error: null | ModuleError<CarProfileUpdateableKeys>
}

export interface VehicleDetailsModule extends ModuleWithData<CarProfileFull> {
  statusUpdating: CommonModule
  permitVerifying: CommonModule
  updating: VehicleUpdatingModule
}

export interface State {
  listModule: PaginationModule<CarProfile>

  // Vehicle details grouped by vehicle id
  vehiclesDetails: Record<string, VehicleDetailsModule | undefined>

  // Trips related to the vehicle, grouped by vehicle id
  vehicleTrips: Record<string, ModuleWithData<TripsByGroups> | undefined>
}

export const getInitialVehicleDetailsState = (): VehicleDetailsModule => ({
  ...getInitialDataModuleState(),
  permitVerifying: getInitialCommonModuleState(),
  statusUpdating: getInitialCommonModuleState(),
  updating: { error: null, loadingKeys: [], successKeys: [] },
})

export default (): State => ({
  listModule: getPagintaionModuleState(),
  vehiclesDetails: {},
  vehicleTrips: {},
})
