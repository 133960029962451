import { parseErrors } from '@/helpers/error-parsers'
import { defineStore } from 'pinia'

import { getInitialCarDataState } from './state'

import * as cardDataApi from '@/api/car-data'
import { handleListModuleState } from '@/helpers/store-state'

export const useCommonDataStore = defineStore('common-data-store', {
  state: getInitialCarDataState,
  actions: {
    async getCarMakes() {
      try {
        this.$patch(state => handleListModuleState('start', state.carMakes))

        const carMakes = (await cardDataApi.getCarMakes()).data.data

        this.$patch(state =>
          handleListModuleState('success', state.carMakes, carMakes)
        )
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch(state =>
          handleListModuleState('error', state.carMakes, error)
        )
      }
    },

    async getAirports() {
      try {
        this.$patch(state => handleListModuleState('start', state.airports))

        const airports = (await cardDataApi.getAirports()).data.data

        this.$patch(state =>
          handleListModuleState('success', state.airports, airports)
        )
      } catch (e) {
        const { error } = parseErrors(e)

        this.$patch(state =>
          handleListModuleState('error', state.airports, error)
        )
      }
    },
  },
})
