import client from './index'

import { ApiResponse } from '@/models/api'
import { AirportPlace, CarMake, CarModel } from '@/models/carData'

const API_HOST = process.env.VUE_APP_API_HOST

const baseURL = API_HOST ? `${API_HOST}` : 'https://app.rentbutik.com/api'

// const baseURL = API_HOST ? `${API_HOST}` : 'https://rental-car-api-test-mi4xrzz6sa-ey.a.run.app/api'

export const getCarMakes = () => {
  return client.get<ApiResponse<CarMake[]>>(`${baseURL}/car-data/makes`)
}

export const getCarModels = (makeId: string) => {
  return client.get<ApiResponse<CarModel[]>>(`${baseURL}/car-data/models`, {
    params: { model_make_id: makeId },
  })
}

export const getAirports = () => {
  return client.get<ApiResponse<AirportPlace[]>>(`${baseURL}/popular-places`)
}
